


















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useService } from '@xstate/vue';
// Import utils ------------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
import AuthMfaSoftwareToken from '@/components/auth/mfa/AuthMfaSoftwareToken.vue';
import AuthMfaActivation from '@/components/auth/mfa/AuthMfaActivation.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogAuthMfaActivation',
  components: { AuthMfaActivation, CompositeDialog, AuthMfaSoftwareToken },
  setup() {
    // References
    const rDialog = ref<any>(null);

    /** Dialog unique identifier */
    const dialogId: DialogId = 'auth-mfa-activation';

    // Services
    const authService = usePodocoreModuleService('auth');
    const mfaService = useService(authService.service.value.children.get('mfa') as any);

    onMounted(() => {
      watch(
        () => rDialog.value.displayContent,
        (value: boolean) => {
          if (value) {
            watch(mfaService.state, (state) => {
              if (state.matches({ verifyingSoftwareToken: 'success' })) {
                rDialog.value.close();
              }
            });
          } else {
            mfaService.send({
              type: 'RESET'
            });
          }
        }
      );
    });

    return {
      // References
      rDialog,
      // Services
      authService,
      mfaService,
      // Values
      dialogId
    };
  }
});
