














// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
import { useService } from '@xstate/vue';
// Import utils ------------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthMfaDeactivation',
  components: { AlertError },
  setup() {
    // Services
    const authService = usePodocoreModuleService('auth');
    const mfaService = useService(authService.service.value.children.get('mfa') as any);

    const isErrored = computed(() =>
      [{ deactivating: 'failure' }].some((element) => mfaService.state.value.matches(element))
    );

    const isDisabled = computed(
      () => !['idle', { deactivating: 'failure' }].some((element) => mfaService.state.value.matches(element))
    );

    const isLoading = computed(() =>
      [{ deactivating: 'processing' }].some((element) => mfaService.state.value.matches(element))
    );

    function deactivate() {
      mfaService.send({
        type: 'DEACTIVATE'
      });
    }

    return {
      // Services
      authService,
      mfaService,
      // Methods
      deactivate,
      // Flags
      isErrored,
      isDisabled,
      isLoading
    };
  }
});
