// Import vendors ----------------------------------------------------------------------------------
import { reactive, ref } from '@vue/composition-api';
import { isUndefined, isString } from 'lodash';
// Import types ------------------------------------------------------------------------------------
import type { Ref } from '@vue/composition-api';
import type { ValidationObserver } from 'vee-validate';
import type { ComputedRef } from '@vue/composition-api';
// Export / declare types --------------------------------------------------------------------------
type Rules = {
  required: boolean;
  email: boolean;
  password: boolean;
  date: string;
  birthDate: string;
  integer: boolean;
  min: number;
  max: number;
  min_value: number;
  max_value: number;
  length: number;
  checkbox: boolean;
  phone: Ref<string>;
  oneOf: Array<number | string | boolean>;
  maxFileSize: number;
  fileTypes: string[];
};

export interface FormField<T = any, D = T> {
  key: string;
  value: T;
  defaultValue?: D;
  rules: Partial<Rules>;
  attributes?: Record<string, string | boolean | number>;
  forceType?: typeof String | typeof Number | typeof Boolean;
  datePicker?: any;
}

export interface FormFieldText<T = any, D = T> extends FormField<T, D> {
  type?: HTMLInputElement['type'] | Ref<HTMLInputElement['type']>;
}

export interface FormFieldFile<T = any, D = T> extends FormField<T, D> {
  type?: HTMLInputElement['type'] | Ref<HTMLInputElement['type']>;
}

export interface FormFieldDate extends FormField<string> {
  picker: string;
  menu: boolean;
}

export interface FormFieldAutoComplete<T = any, D = T> extends Omit<FormField<T, D>, 'value'> {
  value: T | T[];
  items: D[] | T[] | ComputedRef<T[]>;
}

export interface FormFieldSelect<T = any, D = T> extends Omit<FormField<T, D>, 'value'> {
  value: T | T[];
  items: D[] | T[] | ComputedRef<T[]>;
}

export interface FormFieldSelectChips<T = any>
  extends Omit<FormField<T>, 'value'>,
    FormFieldAutoComplete<T> {}

export interface FormFieldButtonToggle<T = any> extends FormField<T> {
  buttons: {
    content: string;
    value: T;
  }[];
}

// -------------------------------------------------------------------------------------------------

/**
 * Use form observer (should be used with "vee-validate")
 * @see https://logaretm.github.io/vee-validate/api/validation-observer.html
 */
export function useFormObserver() {
  return ref<InstanceType<typeof ValidationObserver>>(null!);
}

/**
 * Use form field "text"
 */
export function useFormFieldText<T, D = T>(field: FormFieldText<T, D>) {
  return reactive<FormFieldText<T, D>>(field);
}

/**
 * Use form field "file"
 */
export function useFormFieldFile<T, D = T>(field: FormFieldFile<T, D>) {
  return reactive<FormFieldFile<T, D>>(field);
}

/**
 * Use form field "date"
 */
export function useFormFieldDate(field: FormField<string>) {
  return reactive<FormFieldDate>({
    ...field,
    picker: '',
    menu: false
  });
}

/**
 * Use form field "auto complete"
 */
export function useFormFieldAutoComplete<T, D = T>(field: FormFieldAutoComplete<T, D>) {
  return reactive<FormFieldAutoComplete<T, D>>(field);
}

/**
 * Use form field "select chips"
 */
export function useFormFieldSelectChips<T>(field: FormFieldSelectChips<T>) {
  return reactive<FormFieldSelectChips<T>>(field);
}

/**
 * Use form field "button toggle"
 */
export function useFormFieldButtonToggle<T>(field: FormFieldButtonToggle<T>) {
  return reactive<FormFieldButtonToggle<T>>(field);
}

/**
 * Use form
 */
export function useForm<F>( // TODO : improve typing
  fields: F,
  observer: ReturnType<typeof useFormObserver>,
  action: (fields: F) => void
) {
  async function submit() {
    return observer.value.validate().then((isValid) => {
      if (isValid) {
        action(fields);
      }
    });
  }

  function reset() {
    return observer.value.reset();
  }

  return {
    submit,
    reset
  };
}

export function cleanFieldValue<T>(
  field: Pick<FormField<T>, 'value' | 'forceType' | 'defaultValue'>
): T | null {
  if (isUndefined(field.value) || (isString(field.value) && field.value.trim().length === 0)) {
    return field.defaultValue ?? null;
  } else {
    switch (field.forceType) {
      case String:
        return String(field.value) as any;
      case Number:
        return Number(field.value) as any;
      case Boolean:
        return Boolean(field.value) as any;
      default:
        return field.value;
    }
  }
}
