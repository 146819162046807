
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, h } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import DialogTermsAndConditions from '@/components/dialogs/DialogTermsAndConditions.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AccountInformationDocument',
  components: {
    DialogTermsAndConditions
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  setup(properties, { root }) {
    const busModule = usePodocoreModule('bus');

    const _document = root.$tc(
      `commons.sentences.account.terms-and-conditions.doc-${properties.document.type.toLowerCase()}.title`
    ) as string;

    return () =>
      h('div', { class: 'account__information__document' }, [
        h('DialogTermsAndConditions', {
          props: {
            consentDocument: properties.document,
            filterMeta: { consentDocumentCuid: properties.document.cuid }
          }
        }),
        h(
          'u',
          {
            domProps: {
              innerHTML: _document
            },
            on: {
              '!click': (event: Event) => {
                busModule.publish(
                  busModule.events.openDialog({
                    id: 'terms-and-conditions',
                    meta: { consentDocumentCuid: properties.document.cuid }
                  })
                );
                event.stopPropagation();
                event.preventDefault();
              }
            }
          },
          root.$slots.default
        )
      ]);
  }
});
