











































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onBeforeMount, ref } from '@vue/composition-api';
import { get, capitalize } from 'lodash';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
import { useVersion } from '@/utils/version.utils';
// Import configuration ----------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeCard from '../composite/CompositeCard.vue';
import DialogUdi from '@/components/dialogs/DialogUdi.vue';
import AlertError from '../alerts/AlertError.vue';
import AccountInformationDocument from './AccountInformationDocument.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AccountInformation',
  components: { CompositeCard, DialogUdi, AlertError, AccountInformationDocument },
  setup(_, { root }) {
    const busModule = usePodocoreModule('bus');

    const authService = usePodocoreModuleService('auth');
    const doctorService = usePodocoreModuleService('doctor');

    const { cdn } = useCDN();
    const { currentVersion } = useVersion();

    const udi = ref<string>(`(01)370056578001(10)${currentVersion.value}`);

    const infos = computed(() => [
      {
        title: root.$t('commons.sentences.unique-identifier'),
        subtitle: get(authService, 'state.value.context.user.attributes.sub', 'Unknown')
      },
      {
        title: root.$tc('commons.standards.email'),
        subtitle: get(authService, 'state.value.context.user.attributes.email', 'Unknown')
      },
      {
        title: root.$t('commons.sentences.last-update'),
        subtitle: capitalize(
          (
            root.$moment(get(doctorService, 'state.value.context.doctor.updatedAt', 'Unknown')) as any
          ).calendar()
        )
      }
    ]);

    const documentsRequest = usePodocoreModule('request').useRequest(
      `${apiConfig.default}/documents/latests`
    );

    onBeforeMount(() => {
      documentsRequest.request();
    });

    function openUdi() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'udi'
        })
      );
    }

    return {
      // Values
      infos,
      udi,
      documentsRequest,
      // Methods
      openUdi,
      cdn
    };
  }
});
