














// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { toDataURL } from 'qrcode';
// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'DialogUdi',
  components: { CompositeDialog },
  props: {
    udi: {
      type: String,
      required: true
    }
  },
  setup(properties) {
    const dialogId: DialogId = 'udi';
    const rDialog = ref<any>(null);

    const qrCode = ref<string | null>(null);

    onMounted(() => {
      toDataURL(properties.udi.replace(/[()]/g, ''), { width: 300 })
        .then((qrcode) => (qrCode.value = qrcode))
        .catch(console.error);
    });

    return {
      rDialog,
      dialogId,
      qrCode
    };
  }
});
