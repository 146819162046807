






















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, toRefs } from '@vue/composition-api';
// Import types ------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { FormFieldText } from '@/utils/forms.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'FormFieldText',
  inheritAttrs: false,
  props: {
    field: {
      type: Object as PropType<FormFieldText>,
      required: true
    }
  },
  setup(properties, { slots }) {
    // Use this property to have typing in DOM
    const { field } = toRefs(properties);

    const hasProgressSlot = computed(() => {
      return Boolean(slots.progress);
    });

    const hasAppendSlot = computed(() => {
      return Boolean(slots.append);
    });

    return {
      // Values
      f: field as any as FormFieldText,
      // Flags
      hasProgressSlot,
      hasAppendSlot
    };
  }
});
