









































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthMfaSoftwareToken',
  components: { AlertError },
  props: {
    mfaService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const unknownError = new Error('Unknown error');

    const userCannotScan = ref(false);

    const isSucceeded = computed(() =>
      [
        { associatingSoftwareToken: { generateTokenQrCode: 'success' } },
        'verifyingSoftwareToken'
      ].some((element) => properties.mfaService.state.value.matches(element))
    );

    const isErrored = computed(() =>
      [
        { associatingSoftwareToken: { generateToken: 'failure' } },
        { associatingSoftwareToken: { generateTokenQrCode: 'failure' } }
      ].some((element) => properties.mfaService.state.value.matches(element))
    );

    const isDisabled = computed(
      () =>
        ![
          'idle',
          { associatingSoftwareToken: { generateToken: 'failure' } },
          { associatingSoftwareToken: { generateTokenQrCode: 'failure' } },
          { associatingSoftwareToken: { generateTokenQrCode: 'success' } }
        ].some((element) => properties.mfaService.state.value.matches(element))
    );

    const isLoading = computed(() =>
      [
        { associatingSoftwareToken: { generateToken: 'processing' } },
        { associatingSoftwareToken: { generateTokenQrCode: 'processing' } }
      ].some((element) => properties.mfaService.state.value.matches(element))
    );

    const qrCode = computed(() => {
      return (properties.mfaService.state.value.context as any).associationSoftwareTokenQrCode;
    });

    function cannotScan() {
      userCannotScan.value = true;
    }

    onMounted(() => {
      properties.mfaService.send({
        type: 'ASSOCIATE_SOFTWARE_TOKEN'
      });
    });

    return {
      // Values
      qrCode,
      unknownError,
      userCannotScan,
      // Methods
      cannotScan,
      // Flags
      isSucceeded,
      isErrored,
      isDisabled,
      isLoading
    };
  }
});
