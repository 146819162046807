







































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
// import { useResizeObserver } from '@vueuse/core';
// Import components -------------------------------------------------------------------------------
import Page from '@/components/Page.vue';
import PageRow from '@/components/PageRow.vue';
import AccountInformation from '@/components/account/AccountInformation.vue';
import DoctorProfile from '@/components/doctor/DoctorProfile.vue';
import AuthMfaManagement from '@/components/auth/mfa/AuthMfaManagement.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PageAccount',
  components: {
    Page,
    PageRow,
    AccountInformation,
    DoctorProfile,
    AuthMfaManagement
  },
  setup() {
    const rPage = ref<InstanceType<typeof Page>>(null!);

    const doctorService = usePodocoreModuleService('doctor');

    const doctor = computed(() => {
      return (doctorService.state.value as any).context.doctor;
    });

    /* function resizeGridChild(child: HTMLDivElement) {
      const childContent = child.children[0];

      if (!childContent) throw new Error('Masonry needs at least one child');

      const masonry = rPage.value.$el.querySelectorAll<HTMLDivElement>('.masonry')[0];
      const rowHeight = Number.parseInt(
        window.getComputedStyle(masonry).getPropertyValue('grid-auto-rows'),
        10,
      );
      const rowGap = Number.parseInt(
        window.getComputedStyle(masonry).getPropertyValue('grid-row-gap'),
        10,
      );
      const rowSpan = Math.ceil(
        (childContent.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap),
      );

      child.style.gridRowEnd = 'span ' + rowSpan;
    } */

    /* function resizeAllGridItems() {
      const allChildren = document.querySelectorAll<HTMLDivElement>('.masonry__item');
      allChildren.forEach((child) => {
        resizeGridChild(child);
      });
    } */

    // onMounted(() => {
    //   useResizeObserver(rPage.value.$el, () => {
    //     resizeAllGridItems();
    //   });
    // });

    return {
      // References
      rPage,
      // Services
      doctorService,
      // Values
      doctor
    };
  }
});
