




































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import CompositeCard from '@/components/composite/CompositeCard.vue';
import DialogAuthMfaActivation from '@/components/dialogs/DialogAuthMfaActivation.vue';
import AuthMfaDeactivation from '@/components/auth/mfa/AuthMfaDeactivation.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthMfaManagement',
  components: { CompositeCard, AuthMfaDeactivation, DialogAuthMfaActivation },
  setup() {
    const busModule = usePodocoreModule('bus');

    const authService = usePodocoreModuleService('auth');

    const mfaIsEnabled = computed(() => {
      return (authService.state.value?.context as any).user?.preferredMFA === 'SOFTWARE_TOKEN_MFA';
    });

    function openDialogAuthMfaActivation() {
      busModule.publish(busModule.events.openDialog({ id: 'auth-mfa-activation' }));
    }

    return {
      // Flags
      mfaIsEnabled,
      // Methods
      openDialogAuthMfaActivation
    };
  }
});
