



































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useForm, useFormFieldText, useFormObserver } from '@/utils/forms.utils';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
import FormFieldText from '@/components/forms/FormFieldText.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthMfaActivation',
  components: { AlertError, FormFieldText },
  props: {
    mfaService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    // Form observer
    const rObserver = useFormObserver();

    /** TOTP code */
    const code = useFormFieldText<string>({
      key: 'code',
      value: '',
      rules: {
        required: true,
        length: 7
      }
    });

    // Form manager
    const { submit } = useForm({ code }, rObserver, (fields) => {
      properties.mfaService.send({
        type: 'VERIFYING_SOFTWARE_TOKEN',
        data: {
          code: fields.code.value.replace(/\s/, '')
        }
      } as any);
    });

    const isSucceeded = computed(() =>
      [{ verifyingSoftwareToken: 'success' }].some((element) =>
        properties.mfaService.state.value.matches(element)
      )
    );

    const isErrored = computed(() =>
      [{ verifyingSoftwareToken: 'failure' }].some((element) =>
        properties.mfaService.state.value.matches(element)
      )
    );

    const isDisabled = computed(
      () =>
        ![
          { associatingSoftwareToken: { generateTokenQrCode: 'success' } },
          { verifyingSoftwareToken: 'failure' }
        ].some((element) => properties.mfaService.state.value.matches(element))
    );

    const isLoading = computed(() =>
      [{ verifyingSoftwareToken: 'processing' }].some((element) =>
        properties.mfaService.state.value.matches(element)
      )
    );

    return {
      // References
      rObserver,
      // Fields
      code,
      // Methods
      submit,
      // Flags
      isSucceeded,
      isErrored,
      isDisabled,
      isLoading
    };
  }
});
